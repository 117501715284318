<template>
    <div class="QRScanner" v-if="isApp">
        <div class="scan-box">
            <span class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben73" @click="toggleLight"></span>
        </div>

        <p class="scan-text">请将二维码置于方框中，自动扫描</p>

        <span class="iconfont icon-a-payicon101_pinture" @click="toggleLight"></span>

        <span class="iconfont icon-a-payicon101_zhuanqu_huaban1fuben73" @click="toggleLight"></span>
    </div>

    <div class="QRScanner h5" v-else>
        <div class="h5-input">
            <van-field v-model="value" placeholder="请输入付款码" type="textarea" rows="3" />
        </div>

        <van-button class="btn" type="primary" block round @click="sendRequest(value)">去支付</van-button>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getorderinfo, checkorder } from '@/api'
import { Dialog } from 'vant'

export default {
    name: 'Scan',
    setup() {
        const state = reactive({
            isApp: true,
            lightOn: false,
            frontCamera: false,

            value: ''
        })

        const router = useRouter()
        const route = useRoute()

        if (typeof QRScanner !== 'object') {
            state.isApp = false
            console.log('当前环境：浏览器 H5')
        } else {
            state.isApp = true
            console.log('当前环境：APP webview')
        }

        const sendRequest = text => {
            getorderinfo(text).then(res => {
                if (res.status) {
                    if (res.data.isOuttime) {
                        Dialog.alert({
                            message: res.message
                        }).then(() => router.back())
                        return
                    }

                    if (res.data.STATE !== '2') {
                        Dialog.alert({
                            message: res.message
                        }).then(() => router.back())
                        return
                    }

                    checkorder(res.data.ID).then(result => {
                        if (result.status) {
                            router.replace({ path: '/Payment', query: { ...res.data } })
                        } else {
                            Dialog.alert({
                                message: result.message
                            }).then(() => router.back())
                        }
                    })
                } else {
                    Dialog.alert({
                        message: res.message
                    }).then(() => router.back())
                }
            })
        }

        let id = route.query.id
        if (id && state.isApp) {
            sendRequest(id)
        }

        // 切换闪光灯
        const toggleLight = () => {
            console.log('切换闪光灯')
            if (state.lightOn === false) {
                QRScanner.enableLight((err, status) => {
                    err && console.log(err)
                    state.lightOn = true
                })
            } else {
                QRScanner.disableLight((err, status) => {
                    err && console.log(err)
                    state.lightOn = false
                })
            }
        }

        const doScan = () => {
            // document.getElementById('app').style.backgroundColor = 'black'
            // document.querySelector('body').style.backgroundColor = 'black'
            // return

            if (typeof QRScanner !== 'object') return

            document.getElementById('app').style.backgroundColor = 'transparent'
            document.querySelector('body').style.backgroundColor = 'transparent'

            QRScanner.prepare(onDone)
            function onDone(err, status) {
                if (err) {
                    console.log(err._message)
                }

                if (status.authorized) {
                    QRScanner.scan(displayContents)
                    function displayContents(err, text) {
                        if (err) {
                            console.error(err)
                        } else {
                            // 扫码的后续操作
                            sendRequest(text)
                        }
                    }

                    QRScanner.show()
                } else if (status.denied) {
                    console.log('用户拒绝扫码')
                } else {
                    console.log('未获取数据')
                }
            }
        }

        onMounted(() => {
            doScan()
        })

        onUnmounted(() => {
            document.getElementById('app').style.backgroundColor = '#FFFFFF'
            document.querySelector('body').style.backgroundColor = '#FFFFFF'

            try {
                QRScanner.hide(status => {
                    console.log('关闭扫描' + JSON.stringify(status))
                })
                QRScanner.destroy(status => {
                    console.log('销毁扫描' + JSON.stringify(status))
                })
            } catch (error) {
                console.log(error)
            }
        })

        return {
            ...toRefs(state),
            toggleLight,
            sendRequest
        }
    }
}
</script>

<style lang="scss" scoped>
// .van-nav-bar.van-hairline--bottom {
// }
// .van-nav-bar ::v-deep {
// }

#scanMenu {
    position: absolute;
    bottom: 60px;
    width: 100vw;
}

.QRScanner {
    width: 100%;
    position: relative;
    text-align: center;
    color: #fff;

    .scan-box {
        width: w(280);
        height: h(280);
        position: absolute;
        top: h(120);
        left: 0;
        right: 0;
        margin: 0 auto;
        background: url('~@/assets/img/scan-box-bg.png') no-repeat;
        background-size: 100% 100%;

        .iconfont {
            position: absolute;
            bottom: h(15);
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
        }
    }

    .scan-text {
        position: absolute;
        top: h(430);
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

.h5 {
    .h5-input {
        margin-top: h(20);
    }
    .btn {
        @include btn();
        margin-top: h(20);
    }
}
</style>
